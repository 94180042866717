import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Layout from "./src/components/layout";
const pathUrl = "https://stage.api.twicefun.eclipsemints.com.au/login";
export const wrapRootElement = ({ element }) => {
  let cookie = new Cookies();
  let path = window.location.pathname;
  // console.log("path", path)
  let isExists = path.includes("accesstoken");
  let emptyscope = <Layout>{element}</Layout>;
  if (isExists) {
    path = path.split("/");
    path = path[1].split("=");
    document.cookie = `accesstoken=${path[1]}`;
    window.location.replace("https://stage.twicefun.eclipsemints.com.au/");
  } else if (cookie.get("accesstoken") === undefined) {
    window.location.replace(pathUrl);
    return emptyscope;
  } else {
    const config = {
      method: "get",
      url: "https://sdb-mars-pc-gdp-other.eu.auth0.com/userinfo",
      headers: {
        Authorization: `Bearer ${cookie.get("accesstoken")}`,
      },
    };
    axios(config)
      .then((res) => {
        // console.log(res)
        return <Layout>{element}</Layout>;
      })
      .catch((err) => {
        window.location.replace(pathUrl);
      });
  }
};
